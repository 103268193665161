<template>
  <v-row
    :key="global"
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="8">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>
            {{ global ?
              $t("crudActions.globalIndexModel", { model: $t("models.users.multiple") }) :
              $t("crudActions.indexModel", { model: $t("models.users.multiple") })
            }}
          </span>
          <v-btn
            small
            color="primary"
            :to="{ name: $routes.user.createRoute.name }">
            {{ $t("crudActions.createModel", { model: $t("models.users.single") }) }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <data-table
            class="stripped"
            dense
            :loading="loading"
            :headers="tableColumns"
            :items="users">
            <template #item.bettingPool="{item}">
              <span v-if="item.bettingPool">
                {{ item.bettingPool.displayNumber }}
              </span>
            </template>
            <template #item.bettingPoolReference="{item}">
              <span v-if="item.bettingPool">
                {{ item.bettingPool.reference }}
              </span>
            </template>
            <template #item.action="{ item }">
              <v-btn
                v-if="hasPrivileges(Privilege.loginAs) && $store.getters.userId !== item.id"
                color="primary"
                icon
                small
                @click="signInAsUser(item)">
                <font-awesome-icon icon="sign-out-alt" />
              </v-btn>
              <v-btn
                icon
                color="secondary"
                @click="editUser(item)">
                <font-awesome-icon icon="pencil-alt" />
              </v-btn>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import * as User from "../../api/user.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import { i18n } from "@/i18n";
import { LOGIN_USER as LOGIN_ACTION } from "@/store/actions.js";
import SessionCreateCategory from "@/imported/SessionCreateCategory.json";
import { SET_BETTING_POOL, SET_LANGUAGE, SET_SESSION_MODE } from "@/store/mutations.js";
import LoginRedirectionHelper from "@/services/LoginRedirectionHelper.js";
import Privilege from "@/imported/Privilege.json";
import { URL } from "@/services/Websocket";
import DataTable from "@/components/DataTable.vue";

export default {
  name: "UserIndex",
  components: { DataTable },
  mixins: [ContentLoader],
  props: {
    global: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      users: [],
      Privilege,
    };
  },
  computed: {
    tableColumns() {
      if (this.global) {
        return [
          {
            value: "id",
            text: "#",
          },
          {
            value: "username",
            text: i18n.t("attributes.users.username"),
          },
          {
            value: "group.name",
            text: i18n.t("attributes.users.group"),
          },
          {
            value: "bettingPool",
            text: i18n.t("attributes.users.bettingPool"),

          },
          {
            value: "bettingPoolReference",
            text: i18n.t("attributes.bettingPools.reference"),

          },
          {
            value: "action",
            text: i18n.t("actions.text"),
          },
        ];
      } else {
        return [
          {
            value: "id",
            text: "#",
          },
          {
            value: "username",
            text: i18n.t("attributes.users.username"),
          },
          {
            value: "bettingPool",
            text: i18n.t("attributes.users.bettingPool"),

          },
          {
            value: "bettingPoolReference",
            text: i18n.t("attributes.bettingPools.reference"),

          },
          {
            value: "action",
            text: i18n.t("actions.text"),
          },
        ];

      }
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    "$route"() {
      const context = this;
      this.$startLoading();

      User.fetchUsers(this.global).then(response => {
        context.users = response.data.users;
      }).finally(_ => {
        this.$finishedLoading();
      });
    },
  },
  async mounted() {
    this.$startLoading();

    try {
      const {
        data: { users },
      } = await User.fetchUsers(this.global);

      this.users = users;

      await this.$nextTick();
    } finally {
      this.$finishedLoading();
    }
  },
  methods: {
    editUser({ id }) {
      this.$router.push({
        name: this.$routes.user.editRoute.name,
        params: { id },
      });
    },
    async signInAsUser(user) {
      const {
          $store,
          $route,
          $routes,
          $router,
        } = this,
        form = {
          id: user.id,
          current_user_token: $store.state.token,
        };

      const token = await $store.dispatch(LOGIN_ACTION, {
        form,
        category: SessionCreateCategory.loginAs,
      });
      this.disconnectWebsocket();
      this.connectWebsocket({ token: `Bearer ${token}` });
      LoginRedirectionHelper.getNextPath($router, $route, $routes, $store);
    },
    hasPrivileges(privileges) {
      const userPrivileges = this.$store.getters.privileges;

      if (privileges instanceof Array) {
        return privileges.some((p) => userPrivileges.includes(p));
      }

      return userPrivileges.includes(privileges);
    },
  },
};
</script>
